<script setup lang="ts">
  defineProps<{
    icon?: string
  }>()

  const slots = useSlots()
</script>

<template>
  <div
    class="rounded-md bg-dark-100 px-6 py-12 text-center text-dark-500 dark:bg-dark-800 dark:text-dark-500"
  >
    <SvgImage
      v-if="icon"
      :symbol="icon"
      class="mx-auto mb-2 size-10 text-dark-300/50 dark:text-dark-500"
    />

    <slot>
      {{ $t('global.message.list-empty') }}
    </slot>

    <div
      v-if="slots.add"
      class="mt-5"
    >
      <slot name="add" />
    </div>
  </div>
</template>
